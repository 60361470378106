import {FaSearch, FaWhatsapp} from "react-icons/fa";
import {Link} from "react-router-dom";
import React from "react";

function Rodape(props) {
    return (
        <div>
            <div className='relative inset-x-0 bg-[#006837] text-white py-8 px-4 w-full font-navBarFont'>
                <div className="max-w-screen-lg mx-auto text-center">
                    <a href="https://wa.me/1639113911" target="_blank" rel="noopener noreferrer"
                       className="flex items-center justify-center mb-4">
                        <FaWhatsapp size={40} className="mr-2"/>
                        <span
                            className="text-xl md:text-4xl font-extrabold mb-2 text-[#8CC63F] underline">1639113911</span>
                    </a>
                    <p className="mb-2">contato@protegepiso.com.br</p>
                    <p className="mb-4 font-bold">
                        Ribeirão Preto / SP – Av. Maurílio Biagi, 800 - Ribeirânia, Ribeirão Preto - SP, 14020-750
                    </p>
                    <Link to="/unidades">
                        <button className="bg-[#2D883A] text-white py-2 px-4 rounded flex items-center mx-auto  ">
                            <FaSearch className="mr-2"/> Buscar outras unidades
                        </button>
                    </Link>
                </div>
                <div className="flex justify-center mt-12">Todos os direitos reservados © 2024</div>
                <div className="flex justify-center mt-8 space-x-12">
                    <div><Link to="/termosdeservico">Termos de serviço</Link></div>
                    <div><Link to="/politicadeprivacidade">Política de privacidade</Link></div>
                </div>
            </div>
        </div>
    );
}

export default Rodape;