import React from 'react';
import Mapa from "./Mapa";
import ReactGA from "react-ga4";
import {Helmet} from "react-helmet";

function Unidades(props) {

    ReactGA.send({
        hitType: "pageview",
        page: "/",
        title: "unidades"
    })

    return (
        <div className="pt-24 md:pt-0">
            <Helmet>
                <title>Unidades - Protege Piso</title>
                <meta name="description" content="Veja quais as unidades mais próximas de você." />
                {/* Open Graph */}
                <meta property="og:title" content="Unidades - Protege Piso" />
                <meta property="og:description" content="Veja quais as unidades mais próximas de você." />
                <meta property="og:image" content="hhttps://protegepiso.com.br/wp-content/uploads/elementor/thumbs/logo-contorno-final-3-qr9ktubyh3wykeoagoppheagkansuuyyyeqvz95qtk.png" />
                <meta property="og:url" content="https://protegepiso.com.br/unidades" />
                <meta property="og:type" content="article" />

                {/* Canonical Tag */}
                <link rel="canonical" href="https://protegepiso.com.br/unidades" />
            </Helmet>
            <div>
                <h2 className="flex align-center justify-center mt-12 mb-6 text-2xl md:text-3xl font-bold text-[#17A541]">Encontre a unidade mais próxima de você!</h2>
            </div>
            <Mapa/>
        </div>
    );
}

export default Unidades;