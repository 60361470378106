import React, { useState } from 'react';
import { ReactComponent as BrazilMap } from '../images/br.svg'; // Importe seu SVG do mapa do Brasil

const statesData = {
    'BRAM': { color: '#519E45', name: 'Amazonas', cities: [{ name: 'Manaus', link: '' }] },
    'BRBA': { color: '#519E45', name: 'Bahia', cities: [{ name: 'Camaçari', link: '' }] },
    'BRDF': { color: '#519E45', name: 'Distrito Federal', cities: [{ name: 'Brasília', link: '' }] },
    'BRES': { color: '#519E45', name: 'Espírito Santo', cities: [{ name: 'Vitória', link: '' }] },
    'BRSP': { color: '#519E45', name: 'São Paulo', cities: [
            { name: 'ABC', link: 'https://abc.protegepiso.com.br/' },
            { name: 'Araçoiaba da Serra', link: 'https://aracoiabadaserra.protegepiso.com.br/' },
            { name: 'Barueri', link: 'https://barueri.protegepiso.com.br/' },
            { name: 'Campinas', link: '' },
            { name: 'Campinas Sul', link: '' },
            { name: 'Indaiatuba', link: '' },
            { name: 'Itu', link: '' },
            { name: 'Jundiaí', link: '' },
            { name: 'Limeira', link: '' },
            { name: 'Marília', link: 'https://marilia.protegepiso.com.br/' },
            { name: 'Ribeirão Preto', link: '' },
            { name: 'São José do Rio Preto', link: '' },
            { name: 'São José dos Campos', link: 'https://sjc.protegepiso.com.br/' },
            { name: 'São Paulo', link: '' },
            { name: 'Sertãozinho', link: '' },
            { name: 'Sorocaba', link: '' }
        ] },
    'BRRJ': { color: '#519E45', name: 'Rio de Janeiro', cities: [{ name: 'Rio de Janeiro', link: '' }] },
    'BRGO': { color: '#519E45', name: 'Goiás', cities: [{ name: 'Rio Verde', link: '' }] },
    'BRMG': { color: '#519E45', name: 'Minas Gerais', cities: [
            { name: 'Belo Horizonte', link: 'https://belohorizonte.protegepiso.com.br/' },
            { name: 'Nova Lima', link: '' },
            { name: 'Uberlândia', link: '' },
            { name: 'Varginha', link: '' }
        ] },
    'BRMT': { color: '#519E45', name: 'Mato Grosso', cities: [
            { name: 'Nova Mutum', link: 'https://novamutum.protegepiso.com.br/' },
            { name: 'Sinop', link: '' }
        ] },
    'BRMS': { color: '#519E45', name: 'Mato Grosso do Sul', cities: [
            { name: 'Campo Grande', link: '' },
            { name: 'Dourados', link: '' }
        ] },
    'BRPR': { color: '#519E45', name: 'Paraná', cities: [
            { name: 'Curitiba', link: '' },
            { name: 'Londrina', link: '' },
            { name: 'Maringá', link: '' },
            { name: 'Ponta Grossa', link: '' }
        ] },
    'BRRN': { color: '#519E45', name: 'Rio Grande do Norte', cities: [{ name: 'Natal', link: '' }] },
    'BRRO': { color: '#519E45', name: 'Rondônia', cities: [{ name: 'Porto Velho', link: 'https://portovelho.protegepiso.com.br/' }] },
    'BRRR': { color: '#519E45', name: 'Roraima', cities: [{ name: 'Boa Vista', link: '' }] },
    'BRSC': { color: '#519E45', name: 'Santa Catarina', cities: [
            { name: 'Balneário Camboriú', link: 'https://balneario.protegepiso.com.br/' },
            { name: 'Porto Belo', link: '' }
        ] },
    'BRTO': { color: '#519E45', name: 'Tocantins', cities: [{ name: 'Palmas', link: 'https://palmas.protegepiso.com.br/' }] }
};
function Mapa() {
    const [selectedState, setSelectedState] = useState(null);

    const handleStateClick = (state) => {
        if (statesData[state]) {
            // Alterna a seleção
            setSelectedState(selectedState === state ? null : state);
        }
    };

    return (
        <div className="flex flex-col md:flex-row items-start">
            <div className="w-full md:w-1/2 flex justify-center">
                <BrazilMap
                    onClick={(e) => {
                        const state = e.target.id;
                        if (statesData[state]) {
                            handleStateClick(state);
                        }
                    }}
                    style={{ width: '500px', height: '500px', maxWidth: '100%', maxHeight: '100%' }}
                    className="brazil-map"
                />
            </div>
            <div className="w-full md:w-1/2 mt-4 md:mt-0 md:ml-8">
                {selectedState ? (
                    <div className="cities-list">
                        <h3 className="text-lg font-semibold mb-2">
                            Franquias em {statesData[selectedState].name}:
                        </h3>
                        <ul className="list-disc pl-5">
                            {statesData[selectedState].cities.map((city) => (
                                <li key={city.name} className="mb-2">
                                    {city.link ? (
                                        <a
                                            href={city.link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-blue-500 hover:underline"
                                        >
                                            {city.name}
                                        </a>
                                    ) : (
                                        <span className="text-gray-500">{city.name}</span>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </div>
                ) : (
                    <p className="text-gray-500">Selecione um estado para ver as franquias disponíveis.</p>
                )}
            </div>
            <style>
                {`
                    .brazil-map .state {
                        fill: #CCCCCC;
                        transition: fill 0.3s ease;
                    }

                    /* Hover direto no CSS */
                    .brazil-map .state:hover {
                        fill: #66BB6A; /* Cor ao passar o mouse */
                    }
                    
                    ${Object.keys(statesData)
                    .map(
                        (state) =>
                            `#${state} { fill: ${
                                selectedState === state
                                    ? '#75A336' // Cor ao clicar
                                    : statesData[state].color // Cor padrão
                            }; }`
                    )
                    .join('')}

                    .brazil-map .state:hover {
                        fill: #66BB6A !important; /* !important garante a prioridade no hover */
                    }
                `}
            </style>
        </div>
    );
}

export default Mapa;
