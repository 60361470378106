import React from 'react';
import '../App.css'
import LogoProtege from '../images/logoprotege.png'

const BannerHome = () => {
    return (
        <div className='flex flex-col lg:flex-row relative h-auto lg:h-80 pt-24 md:pt-0'>
            <div className='relative w-full lg:w-[58%] h-64 lg:h-auto bg-cover bg-center'
                 style={{backgroundImage: 'url(foto01.jpg)'}}>
                <img
                    src={LogoProtege}
                    alt='Logo'
                    className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[20%] h-auto'
                />
            </div>
            <div className='flex flex-col justify-center p-4 sm:p-8 w-full lg:w-[42%] text-white bg-[#17A541]'>
                <div className='text-center lg:text-right lg:pr-16'>
                    <h1 className='font-bold text-2xl sm:text-3xl lg:text-4xl mb-4'>
                        Existe uma solução definitiva para proteção do seu piso durante sua obra ou reforma.
                    </h1>
                    <p className='text-lg sm:text-xl'>
                        CONHEÇA A PROTEGE PISO E FIQUE LONGE DE RISCOS
                    </p>
                </div>
            </div>
        </div>


    );
};

export default BannerHome;
