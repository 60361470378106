import React from 'react';
import ReactGA from "react-ga4";

import Rodape from "./Rodape";

import obraCirculo1 from '../images/foto02.jpg';
import obraCirculo2 from '../images/foto03.jpg';
import obraCirculo3 from '../images/foto04.jpg';

import FolderDescricao from '../images/topo.png';
import exemploPintor from '../images/exemplo-pintor.jpg'
import produtoPiso from '../images/icone-piso.png'
import seloBrasil from '../images/selobrasil.png'
import seloProjeto from '../images/seloprojeto.jpg'
import {Helmet} from "react-helmet";


const Folder = () => {
    ReactGA.send({
        hitType: "pageview",
        page: "/",
        title: "folder"
    })

    return (
        <div id="folder" className="pt-24 md:pt-0">
            <Helmet>
                <title>Folder - Protege Piso</title>
                <meta name="description" content="Solução completa, transporte, mão de obra, instalação, manutenção e retirada do sistema é totalmente executado pela Protege Piso sem custos extras." />
                {/* Open Graph */}
                <meta property="og:title" content="Folder - Protege Piso" />
                <meta property="og:description" content="Solução completa, transporte, mão de obra, instalação, manutenção e retirada do sistema é totalmente executado pela Protege Piso sem custos extras." />
                <meta property="og:image" content="hhttps://protegepiso.com.br/wp-content/uploads/elementor/thumbs/logo-contorno-final-3-qr9ktubyh3wykeoagoppheagkansuuyyyeqvz95qtk.png" />
                <meta property="og:url" content="https://protegepiso.com.br/folder" />
                <meta property="og:type" content="article" />

                {/* Canonical Tag */}
                <link rel="canonical" href="https://protegepiso.com.br/folder" />

            </Helmet>

            <div className="w-full h-auto">
                <img src={FolderDescricao} alt="Descrição da imagem" className="w-full h-auto"/>
            </div>

            <div className="flex flex-col md:flex-row font-navBarFont px-4 md:px-12">
                <div className="w-full md:w-1/2 space-y-4 pt-10">
                    <h2 className="text-2xl md:text-3xl font-bold text-[#17A541]">Resistência</h2>
                    <p className="text-sm md:text-base lg:text-lg">
                        O sistema Protege Piso tem capacidade de resistir as ocorrências que sua obra exige.
                        <span className='font-bold my-6 block'>AQUI NÃO TEM IMPROVISO, TEM PROTEÇÃO!</span>
                        O verso da placa possui tecnologia de absorção de impacto. São detalhes que ampliam a segurança
                        na sua obra.
                    </p>
                    <p className="text-sm md:text-base lg:text-lg">
                        Protege Piso é o único sistema de proteção para pisos com desempenho garantido para áreas
                        externas, internas e pisos de madeira.
                    </p>

                    <h2 className="text-2xl md:text-3xl font-bold text-[#17A541]">Instalação</h2>
                    <p className="text-sm md:text-base lg:text-lg">
                        Solução completa, transporte, mão de obra, instalação, manutenção e retirada do sistema é
                        totalmente executado pela Protege Piso sem custos extras.
                    </p>
                    <p className="text-sm md:text-base lg:text-lg">
                        A proteção do seu piso é nossa responsabilidade.
                    </p>

                    <h2 className="text-2xl md:text-3xl font-bold text-[#17A541]">Sistema de locação por m²</h2>
                    <p className="text-sm md:text-base lg:text-lg">
                        O sistema de proteção é alugado. Mais economia para sua obra e sustentabilidade ambiental.
                    </p>

                    <h2 className="text-2xl md:text-3xl font-bold text-[#17A541]">Garantia ilimitada Protege Piso</h2>
                    <p className="text-sm md:text-base lg:text-lg">
                        Protegemos e mantemos seu piso protegido, um compromisso com o resultado. Antecipe o cronograma
                        da sua obra e deixe a proteção de seu piso conosco!
                    </p>
                </div>
                <div className="w-full md:w-2/5 flex items-center overflow-hidden mt-8 md:mt-0">
                    <img
                        src={exemploPintor}
                        alt="Descrição da imagem"
                        className="w-full h-[300px] md:h-[400px] lg:h-[500px] object-contain"
                    />
                </div>
            </div>

            <div className="flex flex-col items-center mt-16 md:mt-32 mb-12">
                <div className="flex flex-col md:flex-row md:space-x-4 space-y-4 md:space-y-0">
                    <img src={obraCirculo1} alt="Imagem 1"
                         className="w-40 h-40 md:w-60 md:h-60 rounded-full object-cover"/>
                    <img src={obraCirculo2} alt="Imagem 2"
                         className="w-40 h-40 md:w-60 md:h-60 rounded-full object-cover"/>
                    <img src={obraCirculo3} alt="Imagem 3"
                         className="w-40 h-40 md:w-60 md:h-60 rounded-full object-cover"/>
                </div>
            </div>

            <div
                className="flex flex-col md:flex-row p-4 md:px-24 lg:px-52 h-auto md:h-96 font-navBarFont space-y-8 md:space-y-0">
                <div className="w-full md:w-1/2 space-y-4">
                    <h2 className="text-2xl md:text-3xl font-bold text-[#17A541]">Novidades</h2>
                    <p className="text-sm md:text-base lg:text-lg font-bold">
                        Cuidamos de toda sua obra, conheça nossa linha de soluções:
                    </p>
                    <ul className="list-disc pl-8 space-y-2 text-sm md:text-base lg:text-lg">
                        <li>Bancadas</li>
                        <li>Louças sanitárias</li>
                        <li>Metais</li>
                        <li>Banheiras</li>
                        <li>Móveis</li>
                        <li>15 anos de experiência</li>
                    </ul>
                </div>

                <div className="w-full md:w-1/2 bg-[#17A541] text-white p-4 md:p-8 flex items-center h-48 md:h-auto">
                    <p className="text-sm md:text-base lg:text-2xl font-extrabold">
                        Mais de 11 anos de experiência e tecnologia para proteção profissional de pisos durante obras ou
                        reformas.
                    </p>
                </div>

            </div>

            <div className="space-y-8 font-navBarFont px-4 md:px-20 mt-16 md:mt-24">
                <div className="space-y-8 font-navBarFont">
                    <div className="flex flex-col md:flex-row items-start md:items-start space-y-4 md:space-y-0">
                        <div className="w-full md:w-1/2 space-y-4 pr-4">
                            <h1 className="text-2xl md:text-3xl font-bold text-[#17A541]">O Produto</h1>
                            <p className="text-sm md:text-base lg:text-lg">
                                O Protege Piso é um sistema inovador de placas flexíveis compostas de materiais de alta
                                resistência e 100% reciclados.
                            </p>
                            <img
                                src={produtoPiso}
                                alt="Imagem 1"
                                className="w-50 h-50 md:w-32 md:h-32 lg:w-52 lg:h-40"
                            />
                        </div>

                        <div className="w-full md:w-1/2 space-y-4 pr-4">
                            <h1 className="text-2xl md:text-3xl font-bold text-[#17A541]">Design</h1>
                            <p className="text-sm md:text-base lg:text-lg">
                                O design das placas foi projetado para gerar proteção completa e profissional para seu
                                piso em qualquer condição. Instalação intuitiva, sulcos que drenam umidade e baixos
                                relevos que retêm resíduos.
                            </p>
                            <p className="text-sm md:text-base lg:text-lg">
                                Desenho aerodinâmico e peso da placa, garantem a fixação sem uso de adesivos.
                            </p>
                            <div className="flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-4">
                                <img
                                    src={seloBrasil}
                                    alt="Imagem 2"
                                    className="w-40 h-35 md:w-32 md:h-32 lg:w-56 lg:h-36"
                                />
                                <img
                                    src={seloProjeto}
                                    alt="Imagem 3"
                                    className="w-40 h-35 md:w-32 md:h-32 lg:w-80 lg:h-36"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bg-green-900 text-white p-8 text-center space-y-4 font-navBarFont mt-16 md:mt-24">
                <h1 className="text-2xl md:text-3xl font-bold">Descubra as vantagens e benefícios de ser um parceiro
                    Protege Piso em sua região.</h1>
                <p className="text-sm md:text-base lg:text-lg">
                    Entre em contato com a gente e conheça uma grande oportunidade de negócio.
                </p>
            </div>

            <Rodape/>
        </div>
    );
};

export default Folder;
